import { Box } from "@outschool/backpack";
import { useTrackEvent } from "@outschool/ui-analytics";
import { Loading } from "@outschool/ui-legacy-component-library";
import { pxToRem } from "@outschool/ui-utils";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { PaymentElement } from "@stripe/react-stripe-js";
import React from "react";

import { StripePaymentElementPaymentMethod } from "../../../shared/Stripe";

export default function StripePaymentElement({
  setIsPaymentElementComplete,
  setPaymentMethod,
  paymentMethod,
  payingUser
}: {
  setIsPaymentElementComplete: (value: boolean) => void;
  setPaymentMethod: (value: StripePaymentElementPaymentMethod) => void;
  paymentMethod: StripePaymentElementPaymentMethod;
  payingUser?: {
    name?: string;
    email?: string;
  };
}) {
  const trackEvent = useTrackEvent();
  const [hasLoaded, setHasLoaded] = React.useState(false);

  return (
    <Box sx={{ paddingBottom: pxToRem(28) }}>
      <Box
        sx={{
          color: "gray900",
          fontSize: pxToRem(20),
          fontWeight: 500,
          paddingBottom: "small"
        }}
      >
        Payment method
      </Box>
      {!hasLoaded && <Loading />}
      <PaymentElement
        onReady={() => setHasLoaded(true)}
        options={{
          fields: {
            billingDetails: {
              /**
               * Stripe is a bit odd for this setup. If you don't need a field, you
               * can set it "never", but you then need to provide the value for
               * that field. The only time we collect the address is for afterpay since
               * it was not sending the user's shipping address alongside the billing.
               *
               * We also only collect the user's name for afterpay and only if it is
               * not set on their Outschool account as that is the only time it is
               * required by stripe
               */
              email: payingUser?.email ? "never" : "auto"
            }
          }
        }}
        onChange={e => {
          const updatedPaymentMethod =
            e.value.type === "card"
              ? StripePaymentElementPaymentMethod.card
              : // @ts-ignore
                StripePaymentElementPaymentMethod[e.value.type];
          setIsPaymentElementComplete(e.complete);
          if (paymentMethod !== updatedPaymentMethod) {
            trackEvent("payment_type_change", {
              type: e.value.type
            });
          }
          setPaymentMethod(updatedPaymentMethod);
        }}
      />
    </Box>
  );
}
