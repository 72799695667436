// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

import { getReferrerAttribution } from "@outschool/attribution";
import {
  CreateAccountMutation as CreateAccountMutationType,
  CreateAccountMutationVariables,
  UpdateCurrentUserMutation as UpdateCurrentUserMutationType,
  UpdateCurrentUserMutationVariables
} from "@outschool/gql-frontend-generated";
import {
  ExecutionResult,
  Mutation,
  MutationFunctionOptions,
  MutationResult,
  gql,
  useMutation
} from "@outschool/ui-apollo";
import { FullCurrentUserFragment } from "@outschool/ui-auth";
import React from "react";

export const updateCurrentUserMutation = gql`
  mutation UpdateCurrentUser(
    $name: String
    $email: String
    $subscribe: Boolean
    $photo: String
    $phone: String
    $isLeader: Boolean
    $payout_email: String
    $video: ID
    $studentAges: [Int!]
    $ageEmailSubscription: Boolean
    $details: UserDetailsInput
    $locale: UserLocale
    $location: LocationInput
    $isRecordingAutopostEnabled: Boolean
    $consented_to_coppa_notice_at: DateTime
    $default_currency: String
    $learner_mode_switch_auth_requirement: LearnerModeSwitchAuthRequirement
    $do_not_sell_my_personal_info: Boolean
    $teacherAttributes: TeacherAttributesInput
    $consent_to_share_class_history: Boolean
    $consent_to_share_favourites: Boolean
    $privacy_state: String
  ) {
    updateCurrentUser(
      name: $name
      email: $email
      subscribe: $subscribe
      photo: $photo
      phone: $phone
      isLeader: $isLeader
      payout_email: $payout_email
      video: $video
      default_currency: $default_currency
      studentAges: $studentAges
      ageEmailSubscription: $ageEmailSubscription
      details: $details
      locale: $locale
      location: $location
      isRecordingAutopostEnabled: $isRecordingAutopostEnabled
      consented_to_coppa_notice_at: $consented_to_coppa_notice_at
      learner_mode_switch_auth_requirement: $learner_mode_switch_auth_requirement
      do_not_sell_my_personal_info: $do_not_sell_my_personal_info
      teacherAttributes: $teacherAttributes
      consent_to_share_class_history: $consent_to_share_class_history
      consent_to_share_favourites: $consent_to_share_favourites
      privacy_state: $privacy_state
    ) {
      ...FullCurrentUserFragment
    }
  }
  ${FullCurrentUserFragment}
`;

export const UpdateCurrentUserMutation = ({
  // @ts-ignore
  children: updateCurrentUser,
  ...props
}) => (
  <Mutation {...props} mutation={updateCurrentUserMutation}>
    {(mutate: $TSFixMe) =>
      updateCurrentUser((variables: $TSFixMe) => mutate({ variables }))
    }
  </Mutation>
);

export function useUpdateCurrentUserMutation() {
  return useMutation<
    UpdateCurrentUserMutationType,
    UpdateCurrentUserMutationVariables
  >(updateCurrentUserMutation);
}
export function updateCurrentUserMutationDecorator(Component: $TSFixMe) {
  return function WrappedComponent(props: $TSFixMe) {
    const [updateCurrentUser] = useUpdateCurrentUserMutation();
    return <Component updateCurrentUser={updateCurrentUser} {...props} />;
  };
}

export const createAccountMutation = gql`
  mutation CreateAccount(
    $name: String
    $email: String!
    $password: String!
    $attribution: Attribution
    $browserTimeZone: String
    $locale: UserLocale
    $isLeader: Boolean
    $subscribe: Boolean
    $isGiftCardSignup: Boolean
    $shouldNotSellPersonalInfo: Boolean
    $sellerOrgPrimaryContactName: String
    $osRef: OsRefInput
    $isOnboardingSignup: Boolean
    $shouldCreateDefaultLearner: Boolean
    $defaultLearnerAge: Int
  ) {
    createAccount(
      name: $name
      email: $email
      password: $password
      attribution: $attribution
      browserTimeZone: $browserTimeZone
      locale: $locale
      isLeader: $isLeader
      subscribe: $subscribe
      isGiftCardSignup: $isGiftCardSignup
      shouldNotSellPersonalInfo: $shouldNotSellPersonalInfo
      sellerOrgPrimaryContactName: $sellerOrgPrimaryContactName
      osRef: $osRef
      isOnboardingSignup: $isOnboardingSignup
      shouldCreateDefaultLearner: $shouldCreateDefaultLearner
      defaultLearnerAge: $defaultLearnerAge
    ) {
      uid
      sessionToken
      refreshToken
      currentUser {
        ...FullCurrentUserFragment
      }
    }
  }
  ${FullCurrentUserFragment}
`;

type CreateAccountMutationOptions = MutationFunctionOptions<
  CreateAccountMutationType,
  Omit<CreateAccountMutationVariables, "osRef">
>;

type UseCreateAccountMutationReturnType = [
  (
    options: CreateAccountMutationOptions
  ) => Promise<ExecutionResult<CreateAccountMutationType>>,
  MutationResult<CreateAccountMutationType>
];

export function useCreateAccountMutation(): UseCreateAccountMutationReturnType {
  const [createAccount, executionStatusVars] = useMutation<
    CreateAccountMutationType,
    CreateAccountMutationVariables
  >(createAccountMutation);
  // Wrap the createAccount mutation to add attribution when it's called
  const createAccountFn = React.useCallback(
    async (options: CreateAccountMutationOptions, anonymousId?: string) => {
      return await createAccount({
        ...options,
        // @ts-ignore TS(2322): Type '{ osRef: any; email?: string | undefined; at... Remove this comment to see the full error message
        variables: {
          ...options.variables,
          osRef: {
            ...getReferrerAttribution(),
            anonymousId
          }
        }
      });
    },
    [createAccount]
  );

  return [createAccountFn, executionStatusVars];
}
