import { Box, Icon } from "@outschool/backpack";
import { faEnvelope } from "@outschool/icons";
import { Trans, useTranslation } from "@outschool/localization";
import { Component } from "@outschool/ownership-areas";
import {
  addParamsToUrl,
  joinParentAccountPath,
  joinTeacherAccountPath,
  loginPath,
  teachPath
} from "@outschool/routes";
import { useAnalytics } from "@outschool/ui-analytics";
import {
  ContinueWithApple,
  ContinueWithFacebook,
  ContinueWithGoogle,
  ContinueWithKakao,
  ContinueWithLine,
  LoginUserFeedback,
  getPostLoginPath,
  useAuthError,
  useCanShowGoogleAuth,
  useShouldShowKakao,
  useShouldShowLine
} from "@outschool/ui-auth";
import {
  AccountDisclaimer,
  TrackedButton
} from "@outschool/ui-components-shared";
import { useHideFacebookSignupExperiment } from "@outschool/ui-components-website";
import React from "react";
import { useHistory } from "react-router";

import {
  APPLE_SIGNIN_CLIENT_ID,
  FACEBOOK_APP_ID,
  GOOGLE_CLIENT_ID
} from "../../shared/Env";
import ActionType from "../actions/ActionType";
import {
  useLoginWithApple,
  useLoginWithRedirect
} from "../hooks/Authentication";
import { useAppState } from "../stores/AppStateProvider";
import Link from "./Link";

interface SignupFormProps {
  onRedirect?: (e: MouseEvent) => void;
  onClickSocialSignup?: () => void;
  promptLogin?: boolean;
  isTeacherFlow?: boolean;
  trackingParameters?: { [key: string]: string };
  trackingUniqueId?: string;
  signupParams?: { [key: string]: string | boolean | number };
  loginParams?: { [key: string]: string | boolean | number };
}

export default function SignupForm({
  onRedirect,
  onClickSocialSignup,
  promptLogin,
  isTeacherFlow,
  trackingParameters,
  trackingUniqueId,
  signupParams,
  loginParams
}: SignupFormProps) {
  const postLoginPath = getPostLoginPath();
  const { t } = useTranslation("client\\components\\SignupForm");
  return (
    <Box
      sx={{
        display: "grid",
        gap: "1em",
        width: "100%"
      }}
    >
      <SignupButtons
        isTeacherFlow={isTeacherFlow}
        trackingParameters={trackingParameters}
        trackingUniqueId={trackingUniqueId}
        onRedirect={onRedirect}
        signupParams={signupParams}
        postLoginPath={postLoginPath}
        onClickSocialSignup={onClickSocialSignup}
      />
      {promptLogin ? (
        <Trans t={t}>
          <Box
            sx={{
              justifySelf: "center"
            }}
          >
            Already have an account?{" "}
            <Link
              data-test-id="login-link"
              onClick={onRedirect}
              to={addParamsToUrl(loginPath(), {
                postLoginPath: loginParams?.redirect ?? postLoginPath,
                ...loginParams
              })}
              trackingName="login"
              trackingUniqueId={trackingUniqueId}
            >
              Log in
            </Link>
          </Box>
        </Trans>
      ) : null}
      <AccountDisclaimer isSignup />
    </Box>
  );
}

function SignupButtons({
  isTeacherFlow,
  trackingParameters,
  trackingUniqueId,
  onRedirect,
  signupParams,
  postLoginPath,
  onClickSocialSignup
}: $TSFixMe) {
  const analytics = useAnalytics();
  const { t } = useTranslation("client\\components\\SignupForm");
  const history = useHistory();
  const [userFeedbackMessage, setUserFeedbackMessage] =
    React.useState<React.ReactNode>(null);
  const appState = useAppState();
  const canShowGoogleAuth = useCanShowGoogleAuth();

  const {
    isGiftCardSignup,
    isOnboardingSignup,
    shouldCreateDefaultLearner,
    defaultLearnerAge
  } = signupParams || {};

  const createParams = {
    isGiftCardSignup,
    isLeader: isTeacherFlow,
    trackingParameters,
    isOnboardingSignup,
    shouldCreateDefaultLearner,
    defaultLearnerAge
  };

  const defaultErrorMessage = t("Sorry that didn't work, try again");
  const handleAuthError = React.useCallback(
    (error: $TSFixMe, message: React.ReactNode = defaultErrorMessage) => {
      OsPlatform.captureError(error, {
        tags: { component: Component.UserAccountManagement }
      });
      setUserFeedbackMessage(message);
    },
    [defaultErrorMessage, setUserFeedbackMessage]
  );

  const generateLoginError = useAuthError();

  const handleLoginSuccess = React.useCallback(
    ({
      sessionToken,
      refreshToken,
      postLoginPath,
      isLearnerTransfer
    }: $TSFixMe) => {
      if (!isLearnerTransfer) {
        appState.appDispatcher.dispatch(ActionType.User.LOGGED_IN, {
          sessionToken,
          refreshToken
        });
        const redirectPath =
          signupParams?.redirect ||
          (isTeacherFlow ? teachPath() : postLoginPath);
        history.push(redirectPath);
      } else {
        appState.appDispatcher.dispatch(
          ActionType.User.REDIRECT_TO_LEARNER_APP,
          {
            transferToken: sessionToken,
            isLearnerLogin: true
          }
        );
      }
    },
    [history, appState.appDispatcher, isTeacherFlow, signupParams?.redirect]
  );

  const handleLoginError = React.useCallback(
    (error: $TSFixMe) => {
      const errorObject = new Error(error);
      handleAuthError(errorObject, generateLoginError(error));
    },
    [handleAuthError, generateLoginError]
  );

  const handleAppleLogin = useLoginWithApple(
    handleLoginSuccess,
    handleLoginError,
    createParams,
    postLoginPath
  );

  const setAuthStrategy = useLoginWithRedirect(
    handleLoginError,
    createParams,
    // @ts-ignore TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    null,
    signupParams?.redirect || postLoginPath
  );

  const showKakao = useShouldShowKakao();
  const showLine = useShouldShowLine();
  const showFacebook = !useHideFacebookSignupExperiment();

  return (
    <React.Fragment>
      <LoginUserFeedback
        userFeedbackMessage={userFeedbackMessage}
        isError={true}
      />
      {canShowGoogleAuth && (
        <ContinueWithGoogle
          trackingName="signup-with-google"
          setAuthStrategy={setAuthStrategy}
          // @ts-ignore TS(2322): Type '() => Promise<string | undefined>' is not as... Remove this comment to see the full error message
          getAnonymousId={analytics.anonymousId}
          clientId={GOOGLE_CLIENT_ID!}
          onClick={onClickSocialSignup}
        />
      )}

      {showLine && (
        <ContinueWithLine
          isLearnerApp={false}
          setAuthStrategy={setAuthStrategy}
          trackingName="signup-with-line"
          getAnonymousId={analytics.anonymousId}
        />
      )}

      {showKakao && (
        <ContinueWithKakao
          isLearnerApp={false}
          setAuthStrategy={setAuthStrategy}
          trackingName="signup-with-kakao"
          getAnonymousId={analytics.anonymousId}
        />
      )}

      {showFacebook && (
        <ContinueWithFacebook
          setAuthStrategy={setAuthStrategy}
          trackingName="signup-with-facebook"
          // @ts-ignore TS(2322): Type '() => Promise<string | undefined>' is not as... Remove this comment to see the full error message
          getAnonymousId={analytics.anonymousId}
          clientId={FACEBOOK_APP_ID!}
          onClick={onClickSocialSignup}
        />
      )}

      <ContinueWithApple
        onSuccess={handleAppleLogin}
        onError={handleAuthError}
        trackingName="signup-with-apple"
        clientId={APPLE_SIGNIN_CLIENT_ID!}
        onClick={onClickSocialSignup}
      />
      <TrackedButton
        component={Link}
        onClick={onRedirect}
        data-test-id="signup-flow-link"
        trackingName="signup-with-email"
        trackingUniqueId={trackingUniqueId}
        to={
          isTeacherFlow
            ? joinTeacherAccountPath()
            : joinParentAccountPath({ ...signupParams, postLoginPath })
        }
        sx={{
          padding: "0 !important",
          display: "flex",
          alignItems: "center"
        }}
      >
        <Box
          sx={{
            fontSize: "1.5em",
            padding: "10px",
            width: "44px"
          }}
        >
          <Icon
            icon={faEnvelope}
            sx={{
              display: "block"
            }}
          />
        </Box>
        <Box
          flex
          sx={{
            flex: 1,
            padding: "12.5px",
            minWidth: "215px",
            justifyContent: "center"
          }}
        >
          {t`Sign Up with Email`}
        </Box>
      </TrackedButton>
    </React.Fragment>
  );
}
