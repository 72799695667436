import { resolve } from "url";

import {
  LearnerRoutes,
  WebsiteRoutes,
  activityAvailabilityPath,
  addParamsToUrl,
  blogPath,
  browseRootPath,
  categorySitemapPath,
  enrolledPath,
  giftCardLandingPath,
  initRoutes,
  joinParentAccountPath,
  joinTeacherAccountPath,
  leadActivitiesPath,
  loginPath,
  makeLocalePrefix,
  publicProfileLink,
  sellerOrgPath,
  teachPath,
  teachTipsBaseUrl,
  teacherDashboardPath
} from "@outschool/routes";
import { dayjs } from "@outschool/time";
import { generatePath } from "react-router";
// @ts-ignore
import slug from "slugify";

import { isClub } from "./Activity";
import * as Env from "./Env";
import * as User from "./User";
import { UserMode } from "./UserMode";

export { getIdFromSlug } from "@outschool/routes";

let HOST_PREFIX: $TSFixMe;
if (Env.isProduction) {
  HOST_PREFIX = "https://outschool.com";
} else if (Env.isStaging && !Env.isReviewApp) {
  HOST_PREFIX = "https://staging.outschool.dev";
} else if (Env.isLangjsApp) {
  HOST_PREFIX = `https://${Env.APP_HOSTNAME}`;
} else if (Env.APP_HOSTNAME) {
  HOST_PREFIX = `https://${Env.APP_HOSTNAME}`;
} else {
  HOST_PREFIX = "http://localhost:3000";
}

let LEARNER_HOST_PREFIX: $TSFixMe;
if (Env.isProduction) {
  LEARNER_HOST_PREFIX = "https://learner.outschool.com";
} else if (Env.isStaging) {
  if (Env.isReviewApp) {
    // We use the same host, with a custom header in review apps. This allows
    // us to avoid supporting multiple subdomains in review apps
    LEARNER_HOST_PREFIX = HOST_PREFIX;
  } else {
    LEARNER_HOST_PREFIX = `https://learner-staging.outschool.dev`;
  }
} else if (Env.LEARNER_HOSTNAME) {
  LEARNER_HOST_PREFIX = `http://${Env.LEARNER_HOSTNAME}`;
} else {
  LEARNER_HOST_PREFIX = "http://localhost:3001";
}

let WEBCAL_URL;
if (Env.isProduction) {
  WEBCAL_URL = "web-calendars.app.outschool.com";
} else if (Env.isStaging) {
  if (Env.isReviewApp && Env.APP_HOSTNAME) {
    WEBCAL_URL = Env.APP_HOSTNAME.replace("-website.", "-web-calendars.");
  } else {
    WEBCAL_URL = `master-web-calendars.review.outschool.dev`;
  }
} else {
  WEBCAL_URL = "localhost:3000";
}

const WEBCAL_PREFIX = `webcal://${WEBCAL_URL}`;

export const webcalUrl = WEBCAL_URL;
/**
 * @deprecated use websiteRoutes.baseUrl
 */
export const hostPrefix = HOST_PREFIX;
/**
 * @deprecated use learnerRotues.baseUrl
 */
export const learnerHostPrefix = LEARNER_HOST_PREFIX;

const routeConfig = {
  appHostName: Env.APP_HOSTNAME,
  locale: Env.LOCALE
};

export const websiteRoutes = new WebsiteRoutes(routeConfig);
export const learnerRoutes = new LearnerRoutes(routeConfig);

export function initRoutesPackage(locale?: string) {
  initRoutes({
    appHostName: Env.APP_HOSTNAME,
    locale
  });
}

export function pathToUrl(path: string, localeOverride?: string | null) {
  // https://nodejs.org/api/url.html#urlresolvefrom-to
  // this function strips anything after the hostname when provided a path
  // so we must build the locale into the path instead
  return resolve(
    HOST_PREFIX,
    makeLocalePrefix(localeOverride ? localeOverride : Env.LOCALE) + path
  );
}

export function hrefToQueryString(s: string): string {
  return (s && s.split("?")[1]) || "";
}

export function extractBasePathname(url: $TSFixMe) {
  if (!url) {
    return undefined;
  }
  let pathname = url.split("://");
  if (pathname.length > 1) {
    pathname = pathname[1];
    return pathname.split("/")[0];
  }
  return undefined;
}

export function parseIntQueryParam(
  query: $TSFixMe,
  paramName: $TSFixMe,
  defaultValue: $TSFixMe
) {
  if (query && paramName in query) {
    const value = parseInt(query[paramName]);
    if (!isNaN(value)) {
      return value;
    }
  }
  return defaultValue;
}

export function parseStringQueryParam(
  query: $TSFixMe,
  paramName: $TSFixMe,
  defaultValue: $TSFixMe
) {
  if (query && paramName in query) {
    return query[paramName];
  }
  return defaultValue;
}

export function makeUrlPrefix(localeOverride?: string | null) {
  return (
    HOST_PREFIX + makeLocalePrefix(localeOverride ? localeOverride : Env.LOCALE)
  );
}

/**
 * @deprecated use websiteRoutes.parentAuthCodeTransferUrl
 */
export const parentAuthCodeTransferUrl =
  websiteRoutes.parentAuthCodeTransferUrl.bind(websiteRoutes);

// New paths should be added to websiteRoutes.ts in @outschool/routes, not here

export function accountPath(isLeader: $TSFixMe) {
  if (isLeader) {
    return leaderSettingsPath();
  } else {
    return learnSchedulePath();
  }
}

export function parentSettingsUrl() {
  return pathToUrl(parentSettingsPath());
}

export function parentSettingsPath() {
  return "/account/parent";
}

export function teacherSettingsPath() {
  return "/account/teacher";
}

export function parentManagePaymentMethodPath() {
  return "/account/parent?managePayments=true";
}

export function parentManagePaymentMethodUrl(locale?: string | null) {
  return pathToUrl(parentManagePaymentMethodPath(), locale);
}

export function paymentsPath() {
  return transactionsPath();
}

export function transactionsPath() {
  return "/account/transactions";
}

export function transactionsUrl(locale?: string | null) {
  return pathToUrl(transactionsPath(), locale);
}

export function receiptPath(orderSlugId: $TSFixMe) {
  return `/account/payments/${orderSlugId}`;
}

export function receiptUrl(orderSlugId: $TSFixMe, locale?: string | null) {
  return pathToUrl(receiptPath(orderSlugId), locale);
}

export function transcriptsPath() {
  return "/account/transcripts";
}

export function emailSettingsPath(token?: string) {
  return addParamsToUrl("/account/email-settings", { token });
}

export function emailSettingsUrl(token?: string, locale?: string | null) {
  return pathToUrl(emailSettingsPath(token), locale);
}

export function activityUrl(activity: $TSFixMe) {
  return pathToUrl(activityPath(activity));
}

export function activityBasePath(isClub: boolean) {
  return isClub ? "/groups/" : "/classes/";
}

export function activityPath(
  activity: ActivityWithSlug,
  noTitleInSlug = false
) {
  return (
    activityBasePath(isClub(activity)) + activitySlug(activity, noTitleInSlug)
  );
}

export function categorySitemapUrl() {
  return pathToUrl(categorySitemapPath());
}

export function classRequestPath() {
  return addParamsToUrl("/online-classes", {
    modal: "classRequest"
  });
}

export function classRequestUrl() {
  return pathToUrl(classRequestPath());
}

interface OnboardingParams {
  onboarding?: any;
}

export function activityCreatePath({
  classRequestUid,
  onboarding
}: OnboardingParams & { classRequestUid?: string } = {}) {
  return addParamsToUrl("/classes/create", {
    classRequestUid,
    onboarding
  });
}

export function clubCreatePath({ onboarding }: OnboardingParams = {}) {
  return addParamsToUrl("/groups/create", {
    onboarding
  });
}

export function activityCreateUrl(classRequestUid: $TSFixMe) {
  return pathToUrl(activityCreatePath({ classRequestUid }));
}

export function sectionUrl(section: $TSFixMe, locale?: string | null) {
  return sectionOrActivityUrl(
    section.activity_uid,
    section,
    undefined,
    undefined,
    locale
  );
}

export function sectionOrActivityUrl(
  activity: ActivityWithSlug,
  section?: ObjectWithUid,
  noTitleInSlug?: boolean,
  showDetails?: boolean,
  locale?: string | null
) {
  return pathToUrl(
    sectionOrActivityPath(activity, section, noTitleInSlug, showDetails),
    locale
  );
}

export function sectionOrActivityUrlForEmails(
  activity: $TSFixMe,
  section: $TSFixMe,
  locale?: string | null
) {
  return pathToUrl(sectionOrActivityPath(activity, section, true), locale);
}

export function sectionOrActivityMeetingTimesUrl(
  activity: $TSFixMe,
  section: $TSFixMe,
  locale?: string | null
) {
  return pathToUrl(
    sectionOrActivityPath(activity, section, true, true),
    locale
  );
}

interface ObjectWithUid {
  uid: string;
}

interface ActivityWithSlug extends ObjectWithUid {
  slug_id?: string | null;
  title?: string | null;
  is_club?: boolean;
  isClub?: boolean;
}

export function sectionOrActivityPath(
  activity: ActivityWithSlug,
  section?: ObjectWithUid,
  noTitleInSlug?: boolean,
  showDetails?: boolean
) {
  const sectionUid = typeof section === "string" ? section : section?.uid;
  let path =
    (isClub(activity) ? "/groups/" : "/classes/") +
    activitySlug(activity, noTitleInSlug);
  if (sectionUid) {
    path = addParamsToUrl(path, { sectionUid, showDetails });
  }

  return path;
}

export function productionActivityUrl(activity: $TSFixMe) {
  return `https://outschool.com${activityPath(activity)}`;
}

export function activitySlug(activity: any, noTitle?: boolean) {
  if (activity.slug_id) {
    if (noTitle) {
      return activity.slug_id;
    }
    const title = activity.title ? activity.title.toLowerCase() : "";
    const cleanTitle = slug(title.replace(/\([^)]*\)/g, ""))
      .replace(/[!'",:.]/g, "")
      .replace(/--/g, "-")
      .replace(/-$/g, "");
    return `${cleanTitle}-${activity.slug_id}`;
  }
  return activity.uid || activity;
}

export function activityEditUrl(activity: $TSFixMe) {
  return pathToUrl(activityEditPath(activity));
}

export function activityEditPath(
  activity: $TSFixMe,
  { onboarding }: OnboardingParams = {}
) {
  return addParamsToUrl(`/classes/${activity.uid || activity}/edit`, {
    onboarding
  });
}

export function clubEditPath(
  activity: $TSFixMe,
  { onboarding }: OnboardingParams = {}
) {
  return addParamsToUrl(`/groups/${activity.uid || activity}/edit`, {
    onboarding
  });
}
export function syllabusPath(
  activity: $TSFixMe,
  { onboarding }: OnboardingParams = {}
) {
  return addParamsToUrl(`/classes/${activity.uid || activity}/syllabus`, {
    onboarding
  });
}

export function activityScheduleUrl(activity: $TSFixMe) {
  return pathToUrl(activitySchedulePath(activity));
}

export function activityAvailabilityUrl(activity: $TSFixMe) {
  return pathToUrl(activityAvailabilityPath(activity));
}

/**
 * @deprecated use websiteRoutes.activitySchedulePath
 */
export function activitySchedulePath(activity: $TSFixMe) {
  return `/classes/${activity.uid || activity}/schedule`;
}

/**
 * @deprecated use websiteRoutes.clubManageListingPath
 */
export function clubManageListingPath(activity: $TSFixMe) {
  return `/groups/${activity.uid}/manage`;
}

/**
 * @deprecated use websiteRoutes.activityApprovalPath
 */
export function activityApprovalPath(
  activity: string | ObjectWithUid,
  mode?: any,
  subject?: any,
  includeFlex?: any
) {
  return addParamsToUrl(
    `/classes/${
      typeof activity === "string" ? activity : activity.uid
    }/approval`,
    {
      mode,
      subject,
      includeFlex
    }
  );
}

export function enrolledUrl(section: $TSFixMe, locale?: string | null) {
  return pathToUrl(enrolledPath(section.uid), locale);
}

export function classroomPath(
  section: string | ObjectWithUid,
  enrollment?: any,
  postFilter?: "all" | "lessons" | "announcements"
) {
  return addParamsToUrl(
    `/classroom/${typeof section === "string" ? section : section.uid}`,
    {
      learnerUid: enrollment
        ? enrollment.learner
          ? enrollment.learner.uid
          : enrollment.learner_uid
        : null,
      postFilter
    }
  );
}

export function lessonsPath(section: string | ObjectWithUid) {
  return addParamsToUrl(
    `/sections/${typeof section === "string" ? section : section.uid}/lessons`,
    { postFilter: "lessons" }
  );
}

export function classroomUrl(
  section: string | ObjectWithUid,
  enrollment?: any
) {
  return pathToUrl(classroomPath(section, enrollment));
}

export function singlePostPath(sectionUid: string, classPostUid: string) {
  return `/classroom/${sectionUid}/posts/${classPostUid}`;
}

export function singlePostUrl(sectionUid: string, classPostUid: string) {
  return pathToUrl(singlePostPath(sectionUid, classPostUid));
}

export function learnerAppClassroomPath(
  section: $TSFixMe,
  enrollment: $TSFixMe
) {
  return addParamsToUrl(`/classroom/${section.uid || section}`, {
    enrollmentUid: enrollment ? enrollment.uid : null
  });
}

export function learnerAppClassroomUrl(
  section: $TSFixMe,
  enrollment: $TSFixMe
) {
  return LEARNER_HOST_PREFIX + learnerAppClassroomPath(section, enrollment);
}

export function learnerAppSinglePostPath(
  section: $TSFixMe,
  classPostUid: $TSFixMe,
  enrollment: $TSFixMe
) {
  return addParamsToUrl(
    `/classroom/${section.uid || section}/posts/${classPostUid}`,
    {
      enrollmentUid: enrollment ? enrollment.uid : null
    }
  );
}

export function learnerAppSinglePostUrl(
  section: $TSFixMe,
  classPostUid: $TSFixMe,
  enrollment: $TSFixMe
) {
  return (
    LEARNER_HOST_PREFIX +
    learnerAppSinglePostPath(section, classPostUid, enrollment)
  );
}

export function privateClassMessagesPath(
  section: $TSFixMe,
  learnerUid = undefined
) {
  return addParamsToUrl(`/private-class-messages/${section.uid || section}`, {
    learnerUid
  });
}

export function teacherStatsUrl() {
  return pathToUrl(teacherStatsPath());
}

export function sellerOrgStatsUrl() {
  return pathToUrl(sellerOrgStatsPath());
}

interface LearnerAppPrivateClubMessagesPathArgs {
  sectionUid: string;
  enrollmentUid?: string;
}

export function learnerAppPrivateClubMessagesPath({
  sectionUid,
  enrollmentUid
}: LearnerAppPrivateClubMessagesPathArgs) {
  return addParamsToUrl(`/group/${sectionUid}/private-group-messages`, {
    enrollmentUid
  });
}

export function learnerAppPrivateClubMessagesUrl({
  sectionUid,
  enrollmentUid
}: LearnerAppPrivateClubMessagesPathArgs) {
  return (
    LEARNER_HOST_PREFIX +
    learnerAppPrivateClubMessagesPath({ sectionUid, enrollmentUid })
  );
}

export function privateClassMessagesUrl(
  section: $TSFixMe,
  learnerUid: $TSFixMe
) {
  return pathToUrl(privateClassMessagesPath(section, learnerUid));
}

export function learnerAppPrivateClassMessagesPath(
  section: $TSFixMe,
  enrollmentUid = undefined
) {
  return addParamsToUrl(
    `/classroom/${section.uid || section}/private-class-messages`,
    {
      enrollmentUid
    }
  );
}

export function learnerAppPrivateClassMessagesUrl(
  section: $TSFixMe,
  enrollmentUid: $TSFixMe
) {
  return (
    LEARNER_HOST_PREFIX +
    learnerAppPrivateClassMessagesPath(section, enrollmentUid)
  );
}

export const recordingPath = WebsiteRoutes.recordingPath;

export const recordingUrl = websiteRoutes.recordingUrl.bind(websiteRoutes);

export function conversationsPath(
  userMode: UserMode,
  queryParams?: { [key: string]: string }
) {
  return addParamsToUrl(
    educatorConversationsBasePath(userMode === UserMode.Educator),
    queryParams || {}
  );
}

const learnerConversationsBasePath = "/teach/conversations/learners";

const educatorConversationsBasePath = (flag: boolean) =>
  flag ? "/teach/conversations" : "/conversations";

export function conversationsLearnerPath(
  limit?: number,
  queryParams?: { [key: string]: string }
) {
  return addParamsToUrl(learnerConversationsBasePath, {
    limit,
    ...queryParams
  });
}

export function conversationsUrl(userMode: UserMode, limitNum?: number) {
  const queryParam = limitNum ? { limit: limitNum.toString() } : undefined;
  return pathToUrl(conversationsPath(userMode, queryParam));
}

/**
 * @deprecated use websiteRoutes.userConversationsPath
 */
export function userConversationsPath(
  userMode: UserMode,
  uid: string,
  limit?: number,
  openModal?: boolean
) {
  const base = educatorConversationsBasePath(userMode === UserMode.Educator);
  return addParamsToUrl(base, {
    limit,
    filterUids: `p_${uid}`,
    ...(openModal && { openMB: "sendMessage" })
  });
}

/**
 * @deprecated use websiteRoutes.userConversationsUrl
 */
export function userConversationsUrl(
  isEducator: boolean,
  uid: string,
  limit?: number
) {
  return pathToUrl(
    userConversationsPath(
      isEducator ? UserMode.Educator : UserMode.Parent,
      uid,
      limit
    )
  );
}

export function makeUrl(path: string) {
  return HOST_PREFIX + path;
}

export function savedSearchesPath() {
  return "/account/saved-searches";
}

export function savedSearchesUrl() {
  return pathToUrl(savedSearchesPath());
}

export function learnSchedulePath(view?: string) {
  const path = "/learn/schedule";
  return view ? addParamsToUrl(path, { view }) : path;
}

export function learnScheduleUrl(locale?: string | null, view?: string) {
  return pathToUrl(learnSchedulePath(view), locale);
}

export function giftEnrollmentPath(slugId: string) {
  return `/gift-invite/${slugId}`;
}

export function giftEnrollmentUrl(slugId: string) {
  return pathToUrl(giftEnrollmentPath(slugId));
}

export function giftCardLandingUrl() {
  return pathToUrl(giftCardLandingPath());
}

export function giftCardAdminPath(user: $TSFixMe) {
  return `${userAdminPath(user)}/giftcards`;
}

export function giftCardRedemptionPath(
  redemptionCode?: string,
  redeemNow?: boolean
) {
  const path = `/giftcard/redeem/${redemptionCode ? redemptionCode : ""}`;
  return redemptionCode && redeemNow
    ? addParamsToUrl(path, { redeemNow })
    : path;
}

export function giftCardRedemptionUrl(
  redemptionCode?: string,
  locale?: string | null
) {
  return pathToUrl(giftCardRedemptionPath(redemptionCode), locale);
}

export function giftCardConfirmationPath(giftCardUid: string) {
  return `/giftcard/confirmation/${giftCardUid}`;
}

export function leaderSettingsPath({ onboarding }: OnboardingParams = {}) {
  return addParamsToUrl("/account/teacher", { onboarding });
}

export function teacherUploadCredentialsPath() {
  return "/teach/credentials";
}

export function leaderSettingsUrl({ onboarding }: OnboardingParams = {}) {
  return pathToUrl(leaderSettingsPath({ onboarding }));
}

export function isInTeacherOnboardingMode({ query: { onboarding } }: $TSFixMe) {
  return !!onboarding;
}

export function loginUrl() {
  return `${HOST_PREFIX}/users/login`;
}

/**
 * @deprecated use websiteRoutes.loginPath
 */
export { loginPath };

/**
 * @deprecated use websiteRoutes.redirectToLearnerUrl
 */
export const redirectToLearnerUrl =
  websiteRoutes.redirectToLearnerUrl.bind(websiteRoutes);

export function defaultPostLoginPath(
  user: $TSFixMe,
  isTeacher: $TSFixMe,
  isSellerOrg = false
) {
  return isSellerOrg
    ? sellerOrgPath()
    : isTeacher
    ? joinTeacherPath(user, isTeacher)
    : browseRootPath();
}

export function groupHostingPoliciesSupportUrl(locale?: string | null) {
  return locale
    ? "https://support.outschool.com/" + locale + "/articles/5684335"
    : "https://support.outschool.com/en/articles/5684335-policies-for-hosting-groups-on-outschool";
}

export function sectionAttendeesUrl(
  section: ObjectWithUid | string,
  learnerUid?: string
) {
  return pathToUrl(
    "/sections/" +
      (typeof section === "string" ? section : section.uid) +
      "/learners" +
      (learnerUid ? `/${learnerUid}` : "")
  );
}

export function changeLearnerEnrollmentUrl(
  section: $TSFixMe,
  learnerUid: $TSFixMe
) {
  return addParamsToUrl(sectionAttendeesUrl(section, learnerUid), {
    changeEnrollment: true
  });
}

export function sectionFeedbackUrl(
  activity: $TSFixMe,
  section: $TSFixMe,
  locale?: string | null
) {
  return pathToUrl(sectionFeedbackPath(activity, section), locale);
}

// Todo: refactor to make 2nd arg `sectionUid: string`
export function sectionFeedbackPath(
  activity: ActivityWithSlug,
  sectionOrSectionUid: ObjectWithUid | string
) {
  const sectionUid =
    typeof sectionOrSectionUid === "string"
      ? sectionOrSectionUid
      : sectionOrSectionUid.uid;
  return activityPath(activity, true) + `/feedback?sectionUid=${sectionUid}`;
}

export function sectionEmailShareUrl(
  activity: ActivityWithSlug,
  section: ObjectWithUid,
  isEnrolled: boolean,
  senderName?: string | null,
  campaignPrefix?: string,
  locale?: string | null
) {
  return pathToUrl(
    sectionEmailSharePath(
      activity,
      section,
      isEnrolled,
      senderName,
      campaignPrefix
    ),
    locale
  );
}

export function sectionEmailSharePath(
  activity: ActivityWithSlug,
  section: ObjectWithUid,
  isEnrolled: boolean,
  senderName?: string | null,
  campaignPrefix?: string
) {
  const query: any = {};
  if (section) {
    Object.assign(query, {
      sectionUid: section && section.uid,
      showDetails: true
    });
  }
  if (isEnrolled) {
    query.isEnrolled = "true";
  }
  if (senderName) {
    query.senderName = senderName;
  }
  if (campaignPrefix) {
    query.campaignPrefix = campaignPrefix;
  }
  return addParamsToUrl(`${activityPath(activity)}/email`, query);
}

export function sectionICalUrl(section: $TSFixMe) {
  return pathToUrl("/sections/" + section.uid + "/ical");
}

export function sectionJoinUrl(section: $TSFixMe, enrollment: $TSFixMe) {
  return pathToUrl(sectionJoinPath(section, enrollment));
}

export function sectionPath(section: $TSFixMe) {
  return "/sections/" + (section.uid || section);
}

export function sectionMeetingsPath(section: $TSFixMe) {
  return "/sections/" + (section.uid || section) + "/meetings";
}

export function sectionEditPath(section: $TSFixMe) {
  return "/sections/" + section.uid + "/edit";
}

export function sectionPostManagementPath(section: $TSFixMe) {
  return `/sections/${section.uid}/posts`;
}

export function sectionAssignmentsPath(section: $TSFixMe) {
  return `/sections/${section.uid}/assignments`;
}

export function sectionMeetingsUrl(section: $TSFixMe) {
  return pathToUrl(sectionMeetingsPath(section));
}

export function sectionEditUrl(section: $TSFixMe) {
  return pathToUrl(sectionEditPath(section));
}

export function sectionJoinPath(section: $TSFixMe, enrollment: $TSFixMe) {
  return (
    "/sections/" +
    section.uid +
    "/join" +
    (enrollment ? `?euid=${enrollment.uid}` : "")
  );
}

export function parentUrl(parentUid: $TSFixMe) {
  return pathToUrl("/parents/" + parentUid + "?preview=true");
}

export function logoutPath() {
  return "/users/logout";
}

export function helpPath() {
  return "/help";
}

export function helpUrl() {
  return pathToUrl(helpPath());
}

export function unsubscribeUrl(email: $TSFixMe) {
  return pathToUrl(unsubscribePath(email));
}

export function unsubscribePath(email: $TSFixMe) {
  return addParamsToUrl("/unsubscribe", {
    email,
    email_sent: dayjs().toISOString()
  });
}

export function emailPreferenceUnsubscribePath({ name, token }: $TSFixMe) {
  return addParamsToUrl("/account/email-settings", {
    name,
    token
  });
}

export function emailPreferenceUnsubscribeUrl({
  name,
  token,
  locale
}: {
  name: string;
  token: string;
  locale?: string | null;
}) {
  return pathToUrl(emailPreferenceUnsubscribePath({ name, token }), locale);
}

export function listUnsubscribeUrl({
  name,
  token
}: {
  name: string;
  token: string;
}) {
  const path = addParamsToUrl("/unsubscribe", {
    name,
    token
  });
  return pathToUrl(path);
}

export function unsubscribedConfirmationUrl(email: $TSFixMe) {
  return pathToUrl("/unsubscribed?email=" + encodeURIComponent(email));
}

export function unfollowUrl(email: $TSFixMe, leaderUid: $TSFixMe) {
  return pathToUrl(
    "/unfollow?email=" + encodeURIComponent(email) + "&leaderUid=" + leaderUid
  );
}

export function unfollowedConfirmationUrl(
  email: $TSFixMe,
  leaderUid: $TSFixMe
) {
  return pathToUrl(
    "/unfollowed?email=" + encodeURIComponent(email) + "&leaderUid=" + leaderUid
  );
}

export function unscheduleRequestUrl(
  userSlugId: $TSFixMe,
  activityUid: $TSFixMe
) {
  return addParamsToUrl(pathToUrl("/unschedule-request"), {
    usid: userSlugId,
    activityUid
  });
}

export function unscheduleRequestConfirmationUrl(
  userSlugId: $TSFixMe,
  activityUid: $TSFixMe
) {
  return addParamsToUrl(pathToUrl("/unscheduled-request"), {
    activityUid
  });
}

export function parentRootPath() {
  return "/parent";
}

export function isTeachPage(pathname: $TSFixMe) {
  return pathname === leadActivitiesPath();
}

export function isSectionDetailPage(location: $TSFixMe) {
  // TODO: make this route all pathname,no query
  return (
    location.pathname.indexOf("/classes") === 0 && location.query.sectionUid
  );
}

export function isSectionDetailPageOfSection(
  location: $TSFixMe,
  section: $TSFixMe
) {
  return (
    (isSectionDetailPage(location) &&
      location.query.sectionUid === section.uid) ||
    location.pathname.indexOf(`/enrolled/${section.uid}`) === 0 ||
    location.pathname.indexOf(`/classroom/${section.uid}`) === 0
  );
}

export function sectionEnrollPath(
  activity: $TSFixMe,
  section: $TSFixMe,
  isAutoScheduled: boolean = false
) {
  const basePath = activityPath(activity || (section && section.activity_uid));
  return `${basePath}/enroll?sectionUid=${section.uid || section}${
    isAutoScheduled ? "&isAutoScheduled=true" : ""
  }`;
}

export function sectionEnrollUrl(
  activity: $TSFixMe,
  section: $TSFixMe,
  isAutoScheduled: boolean = false,
  locale?: string | null
) {
  return pathToUrl(
    sectionEnrollPath(activity, section, isAutoScheduled),
    locale
  );
}

export function selfPacedCharterSchoolEnrollPath(activity: ActivityWithSlug) {
  const basePath = activityPath(activity);
  return `${basePath}/charter-enroll`;
}

export function sectionCharterSchoolEnrollPath(
  activity: $TSFixMe,
  section: $TSFixMe
) {
  const basePath = activityPath(activity || (section && section.activity_uid));
  return `${basePath}/charter-enroll?sectionUid=${section.uid || section}`;
}

// Waitlist urls start

export function parentManageWaitlistsPath(locale?: string | null) {
  return pathToUrl("/learn/schedule?view=classes&tab=waitlisted", locale);
}

export function sectionWaitlistUrl(
  activity: any,
  section: any,
  isAutoScheduled: boolean = false,
  locale?: string | null
) {
  const basePath = activityPath(activity || (section && section.activity_uid));
  const paramPath = addParamsToUrl(`${basePath}/waitlist`, {
    sectionUid: section.uid ?? section,
    ...(isAutoScheduled && { isAutoScheduled })
  });

  return pathToUrl(paramPath, locale);
}

// Waitlist urls end

export function signupPath() {
  return "/users/signup";
}

export function signupUrl() {
  return pathToUrl(signupPath());
}

export function changePinPath() {
  return "/organization/change-pin";
}

export function changePinUrl() {
  return pathToUrl(changePinPath());
}

export function urlForLocation(location: $TSFixMe) {
  return (
    !!location &&
    [location.protocol, location.pathname, location.search].join("")
  );
}

export function inviteUrl(locale?: string | null) {
  return pathToUrl(invitePath(), locale);
}

export function invitePath() {
  return "/invite";
}

export function referTeachersUrl(locale?: string | null) {
  return pathToUrl(referTeachersPath(), locale);
}

export function referTeachersPath() {
  return "/refer-teachers";
}

export function joinUrl(user: $TSFixMe, params = {}) {
  return addParamsToUrl(pathToUrl("/"), {
    signup: true,
    usid: user ? user.slug_id : undefined,
    ...params
  });
}

export function parentProfileJoinUrl(user: $TSFixMe, params = {}) {
  return addParamsToUrl(parentUrl(user?.uid || ""), {
    // set to false so we don't set the global signup modal to open,
    // parent profile has a non modal sign up
    signup: false,
    usid: user ? user.slug_id : undefined,
    ...params
  });
}

export function publicParentProfileUrl(user: $TSFixMe) {
  return pathToUrl(
    publicProfileLink({
      uid: user?.uid || "",
      profile_link_uid: user?.profile_link_uid
    })
  );
}

export function parentProfileWithLinkUidJoinUrl(user: $TSFixMe, params = {}) {
  return addParamsToUrl(
    pathToUrl(
      publicProfileLink({
        uid: user?.uid || "",
        profile_link_uid: user?.profile_link_uid
      })
    ),
    {
      // set to false so we don't set the global signup modal to open,
      // parent profile has a non modal sign up
      signup: false,
      usid: user ? user.slug_id : undefined,
      ...params
    }
  );
}

export function teacherJoinUrl(user: $TSFixMe, params = {}) {
  return addParamsToUrl(teachUrl(), {
    signup: true,
    usid: user ? user.slug_id : undefined,
    ...params
  });
}

export function leadActivitiesUrl() {
  return pathToUrl(leadActivitiesPath());
}

export function slugifyTitle(title: $TSFixMe) {
  if (title) {
    return title
      .toLowerCase()
      .replace(/\([^)]*\)/g, "")
      .replace(/[!'",:.&]/g, "")
      .replace(/[\s]/g, "-")
      .replace(/[-]+/g, "-")
      .replace(/-$/g, "");
  } else {
    return null;
  }
}

export function sellerOrgApplyPath() {
  return "/organization/apply";
}

export function sellerOrgAgreementPath() {
  return "/organization/agreement";
}

export function sellerOrgAgreementForUserPath(userUid: $TSFixMe) {
  return `/organization/${userUid}/agreement`;
}

export function orgTerms() {
  return `/terms-for-org-teachers`;
}

export function orgTermsUrl() {
  return pathToUrl(orgTerms());
}

export function sellerOrgTeachersPath() {
  return "/organization/teachers";
}

export function sellerOrgTeachersUrl() {
  return pathToUrl(sellerOrgTeachersPath());
}

export function sellerOrgDashboardPath() {
  return "/organization/dashboard";
}

export function sellerOrgSchedulePath() {
  return "/organization/schedule";
}

export function sellerOrgClassesPath() {
  return "/organization/classes";
}

export function sellerOrgClubsPath() {
  return "/organization/groups";
}

export function sellerOrgSectionsPath() {
  return "/organization/sections";
}

export function sellerOrgProfilePath() {
  return "/organization/profile";
}

export function sellerOrgStatsPath() {
  return "/organization/stats";
}

export function sellerOrgFeedbackPath() {
  return "/organization/feedback";
}

export function sellerOrgOperationsPath() {
  return "/organization/operations";
}

export function sellerOrgCredentialsPath() {
  return "/organization/credentials";
}

export function teachBasePath() {
  return teachPath() + "/";
}

export function teachUrl() {
  return pathToUrl(teachPath());
}

export function teacherClassesPath() {
  return "/teach/classes";
}

export function teacherClubsPath() {
  return "/teach/groups";
}

export function teacherClassesUrl() {
  return pathToUrl(teacherClassesPath());
}

export function teacherApplyPath() {
  return "/teach/apply";
}

export function teacherSectionsPath() {
  return "/teach/sections";
}

export function teacherCouponsPath() {
  return "/teach/coupons";
}

export function teacherCouponsDetailsPath({ uid }: $TSFixMe) {
  return `/teach/coupons/${uid}`;
}

export function teacherCouponsCreatePath() {
  return "/teach/coupons/create";
}

export function teacherSectionsUrl() {
  return pathToUrl(teacherSectionsPath());
}

export function teacherFeedbackPath() {
  return "/teach/feedback";
}

export function orgTeacherAvailabilityPath() {
  return `/org-teacher/availability`;
}

export function teacherAvailabilityPath() {
  return "/teach/availability";
}

export function teacherStatsPath() {
  return "/teach/stats";
}

export function teacherMarketingPath() {
  return "/teach/marketing";
}
export function teacherBroadcastMessagePath() {
  return "/teach/broadcast";
}

export function orgTeacherAvailabilityUrl() {
  return pathToUrl(orgTeacherAvailabilityPath());
}

export function teacherAvailabilityUrl() {
  return pathToUrl(teacherAvailabilityPath());
}

export function sectionLearnersPath(section: $TSFixMe) {
  return `/sections/${section.uid}/learners`;
}

export function sectionRecordingsPath(section: $TSFixMe) {
  return `/sections/${section.uid}/recordings`;
}

export function sectionRecordingsUrl(section: $TSFixMe) {
  return pathToUrl(sectionRecordingsPath(section));
}

export function sectionLearnersUrl(section: $TSFixMe) {
  return pathToUrl(sectionLearnersPath(section));
}

export function sectionLearnerPath(
  section: $TSFixMe,
  learnerOrEnrollment: $TSFixMe
) {
  return `/sections/${section.uid}/learners/${learnerOrEnrollment.uid}`;
}

export function sectionLearnerUrl(section: $TSFixMe, learner: $TSFixMe) {
  return pathToUrl(sectionLearnerPath(section, learner));
}

export function joinParentLearnersPath(params?: any) {
  return addParamsToUrl("/join/learners", params);
}

export function joinParentPath(
  user: $TSFixMe,
  isLoggedIn: $TSFixMe,
  fallbackPath = ""
) {
  if (!user || !isLoggedIn) {
    return joinParentAccountPath();
  } else if (!User.preferredAges(user).length) {
    return joinParentLearnersPath();
  } else {
    return fallbackPath;
  }
}

export function joinTeacherPath(user?: any, isLeader?: boolean) {
  if (!user || !isLeader) {
    return joinTeacherAccountPath();
  } else {
    return teacherDashboardPath();
  }
}

export function completeProfilePath(
  user?: any,
  isLoggedIn?: boolean,
  isLeader?: boolean
) {
  return isLeader
    ? joinTeacherPath(user, isLoggedIn)
    : joinParentPath(user, isLoggedIn, parentSettingsPath());
}

export function impersonateUrl(token: $TSFixMe) {
  return HOST_PREFIX + "/switch_user?token=" + token;
}

export function supportUrl(locale?: string | null) {
  return locale
    ? "https://support.outschool.com/" + locale
    : "https://support.outschool.com";
}

export function understandingOngoingClassesSupportUrl(locale?: string | null) {
  return supportUrl(locale) + "/articles/2272596";
}

export function teacherProductUpdatesUrl() {
  return addParamsToUrl(`${teachTipsBaseUrl()}/tag/product-updates/`, {
    utm_source: "product",
    utm_medium: "teacher_dashboard",
    utm_campaign: "product_updates_link"
  });
}

export function calendarUrl(calendarUid: $TSFixMe, type: $TSFixMe) {
  return WEBCAL_PREFIX + "/calendars/" + calendarUid + "/" + type + ".ics";
}

export function intercomPopUp() {
  return "/?intercom=true";
}

export function blogUrl(articleSlug = "") {
  return HOST_PREFIX + blogPath(articleSlug);
}

export function emailConfirmationUrl(user: $TSFixMe, token: $TSFixMe) {
  return addParamsToUrl(pathToUrl(`/user/${user.uid}/confirm`, user.locale), {
    token
  });
}

export function otherEmailsConfirmationUrl(user: $TSFixMe, token: $TSFixMe) {
  return addParamsToUrl(
    pathToUrl(`/user/${user.uid}/confirm-other-emails`, user.locale),
    {
      token
    }
  );
}

export function learnerEmailConfirmationUrl(
  learner: $TSFixMe,
  token: $TSFixMe
) {
  return addParamsToUrl(HOST_PREFIX + `/learner/${learner.uid}/confirm`, {
    token
  });
}

export function learnerProfilePath(learner: $TSFixMe) {
  return `/learner/${learner.uid}/profile`;
}

export function mergeAccountRequestPath() {
  return "/accounts/merge-request";
}

export function mergeAccountConfirmUrl(email: $TSFixMe, token: $TSFixMe) {
  return addParamsToUrl(pathToUrl("/accounts/merge-request/confirm"), {
    email,
    token
  });
}

// Admin links
export function activityAdminPath(activity: $TSFixMe) {
  return "/admin/classes/" + activity.uid;
}

export function couponAdminPath() {
  return `/admin/coupons`;
}

export function couponViewAdminPath(coupon: $TSFixMe) {
  return `/admin/coupons/${coupon.uid}`;
}

export function couponCreateAdminPath(type: string) {
  return `/admin/coupons/create?type=${type}`;
}

export function sectionAdminPath(section: { uid: string }) {
  return "/admin/sections/" + section.uid;
}

export function sectionAdminUrl(section: $TSFixMe) {
  return pathToUrl(sectionAdminPath(section));
}

export function userAdminPathForUid(userUid: $TSFixMe) {
  return `/admin/users/${userUid}`;
}

export function userAdminPath(user: $TSFixMe) {
  return userAdminPathForUid(user.uid);
}

export function userAdminUrl(user: $TSFixMe) {
  return pathToUrl(userAdminPath(user));
}

export function userOnboardTeacherRolePath(user: $TSFixMe) {
  return `/admin/users/${user.uid}/onboard-teacher`;
}

export function userOnboardTeacherRolePathUrl(user: $TSFixMe) {
  return pathToUrl(userOnboardTeacherRolePath(user));
}

export function reviewClassesPath() {
  return "/admin/review-classes";
}

export function reviewTeachersPath() {
  return "/admin/review-teachers";
}

export function reviewTeacherPath(teacher: $TSFixMe) {
  return `/admin/review-teachers/${teacher.uid}`;
}

export function pricingOfferAdminPath(showDeleted = false) {
  return addParamsToUrl(`/admin/pricing-offers`, {
    showDeleted: showDeleted || undefined
  });
}

export function pricingOfferAdminCreatePath() {
  return `/admin/pricing-offers/create`;
}

export function pricingOfferAdminEditPath(pricingOffer: $TSFixMe) {
  return `/admin/pricing-offers/${pricingOffer.uid}/edit`;
}

export function pricingOfferAdminDashboardForOrganizationPath(
  organizationUid: string
) {
  return `/admin/pricing-offers/dashboard?organizationUid=${organizationUid}`;
}

export function pricingOfferAdminDashboardForUidPath(uid: string) {
  return `/admin/pricing-offers/dashboard?uid=${uid}`;
}

export function learnerVerificationAdminDashboardPath() {
  return `/admin/learner-verification`;
}

export function learnerVerificationAdminDashboardUrl() {
  return HOST_PREFIX + learnerVerificationAdminDashboardPath();
}

export function activityStatsViewUrl(
  adminDashboardUrl: $TSFixMe,
  activityUid: $TSFixMe
) {
  if (!adminDashboardUrl) {
    return HOST_PREFIX;
  }
  return addParamsToUrl(`${adminDashboardUrl}/dashboard/47`, {
    activity_uid: activityUid
  });
}

export function teacherStatsViewUrl(
  adminDashboardUrl: $TSFixMe,
  teacherUid: $TSFixMe
) {
  if (!adminDashboardUrl) {
    return HOST_PREFIX;
  }
  return addParamsToUrl(`${adminDashboardUrl}/dashboard/22`, {
    user_uid: teacherUid
  });
}

export function welcomeCallUrl(firstName: $TSFixMe, email: $TSFixMe) {
  // https://support.youcanbook.me/article/93-pre-fill-your-booking-form-and-pass-customer-data-through
  return addParamsToUrl("https://outschool-kickoff.youcanbook.me/", {
    FNAME: firstName,
    EMAIL: email
  });
}

export function stripeOutschoolLogoUrl() {
  return "https://s3.amazonaws.com/stripe-uploads/acct_16ULSSI4hzsXqJKOmerchant-icon-1438280538115-logo-twitter.png";
}

export function buyGiftCardPath() {
  return "/giftcard/buy";
}

export function testZoomConnectionUrl() {
  return "https://outschool.zoom.us/test";
}

export function dailyOutschoolerUrl() {
  return HOST_PREFIX + "/daily-outschooler";
}

export function teacherInvitePath() {
  return `/teach/invite`;
}

export function teacherInviteUrl() {
  return pathToUrl(teacherInvitePath());
}

export function topicRequestsUrl() {
  return addParamsToUrl(`${teachTipsBaseUrl()}/parent-topic-requests`, {
    utm_source: "product",
    utm_medium: "teacher_apply",
    utm_campaign: "product_link"
  });
}

export function usingExternalTechToolsUrl() {
  return addParamsToUrl(
    `${teachTipsBaseUrl()}/handbook/using-external-tech-tools-in-your-classroom/`,
    {
      utm_source: "product",
      utm_medium: "class_edit",
      utm_campaign: "product_link"
    }
  );
}

export function approvedUnapprovedResourcesUrl() {
  return addParamsToUrl(
    `${teachTipsBaseUrl()}/handbook/approved-unapproved-external-teaching-tools`,
    {
      utm_source: "product",
      utm_medium: "class_edit",
      utm_campaign: "product_link"
    }
  );
}

export function sitemapUrl(sitemapName: $TSFixMe) {
  return pathToUrl("/sitemaps/" + sitemapName);
}

export function outschoolForEmployersUrl(contact = false) {
  return `https://info.outschool.com/outschool-for-employers${
    contact ? "-contact" : ""
  }`;
}

export function teacherBehaviorBreakdownUrl(teacherUid: $TSFixMe) {
  return `https://metabase.outschool.dev/dashboard/654?teacher_uid=${teacherUid}`;
}

export function parentDrilldownUrl(parentUid: $TSFixMe) {
  return `https://metabase.outschool.dev/dashboard/24?user_uid=${parentUid}`;
}

export function classVideoGuidelinesUrl() {
  return addParamsToUrl(
    `${teachTipsBaseUrl()}/four-tips-for-creating-engaging-authentic-videos-to-promote-your-courses/`,
    {
      utm_source: "product",
      utm_medium: "class_edit",
      utm_campaign: "product_link"
    }
  );
}

/**
 * Needs to stay in sync with learnerApp/src/lib/Routes.ts `clubPath`
 */
export function clubPath(sectionUid: $TSFixMe, { enrollmentUid = null } = {}) {
  return addParamsToUrl(`/group/${sectionUid}`, { enrollmentUid });
}

export function clubUrl(sectionUid: $TSFixMe, { enrollmentUid = null } = {}) {
  return pathToUrl(clubPath(sectionUid, { enrollmentUid }));
}

export function learnerAppClubUrl(
  sectionUid: $TSFixMe,
  { enrollmentUid = null } = {}
) {
  return LEARNER_HOST_PREFIX + clubPath(sectionUid, { enrollmentUid });
}

/**
 * Needs to stay in sync with
 * - @outschool/routes:`clubPostPath` AND
 * - learnerApp/src/lib/Routes.ts `clubPostPath`
 */
export const CLUB_POST_COMMENT_UID_PARAM_NAME = "commentUid";
export function clubPostPath({
  sectionUid,
  classPostUid,
  enrollmentUid,
  commentUid
}: {
  sectionUid: string;
  classPostUid: string;
  enrollmentUid?: string;
  commentUid?: string;
}) {
  return addParamsToUrl(`/group/${sectionUid}/posts/${classPostUid}`, {
    enrollmentUid: enrollmentUid ?? null,
    [CLUB_POST_COMMENT_UID_PARAM_NAME]: commentUid ?? null
  });
}

interface ClubPostCommentArgs {
  sectionUid: string;
  classPostUid: string;
  classPostCommentUid: string;
  enrollmentUid?: string;
}
export function clubPostCommentPath({
  sectionUid,
  classPostUid,
  classPostCommentUid,
  enrollmentUid
}: ClubPostCommentArgs) {
  return addParamsToUrl(
    `/group/${sectionUid}/posts/${classPostUid}/comments/${classPostCommentUid}`,
    {
      enrollmentUid: enrollmentUid ?? null
    }
  );
}

interface LearnerAppClubPostUrlArgs {
  sectionUid: string;
  classPostUid: string;
  enrollmentUid: string;
}
export function learnerAppClubPostUrl({
  sectionUid,
  classPostUid,
  enrollmentUid
}: LearnerAppClubPostUrlArgs) {
  return (
    LEARNER_HOST_PREFIX +
    clubPostPath({ sectionUid, classPostUid, enrollmentUid })
  );
}

export function learnerAppClubPostCommentUrl({
  sectionUid,
  classPostUid,
  enrollmentUid,
  classPostCommentUid
}: LearnerAppClubPostUrlArgs & {
  classPostCommentUid: string;
}) {
  return (
    LEARNER_HOST_PREFIX +
    clubPostCommentPath({
      sectionUid,
      classPostUid,
      enrollmentUid,
      classPostCommentUid
    })
  );
}

export function clubPostUrl({
  sectionUid,
  classPostUid,
  commentUid
}: {
  sectionUid: string;
  classPostUid: string;
  commentUid?: string;
}) {
  return pathToUrl(clubPostPath({ sectionUid, classPostUid, commentUid }));
}

export function partnershipCommunityPath() {
  return "/teachers/partnership-community";
}

export function organizationAdminPath(showDeleted = false, type = "ShowAll") {
  return addParamsToUrl(`/admin/organizations`, {
    showDeleted: showDeleted || undefined,
    type: type && type !== "ShowAll" ? type : undefined
  });
}

export const CategoryLandingPagesPath = "/online-classes/:basePath?/:category";

export function categoryPagePath(category: $TSFixMe, basePath = "") {
  return generatePath(CategoryLandingPagesPath, {
    basePath:
      basePath && basePath !== "" && basePath !== null ? basePath : undefined,
    category
  });
}

const regexStripWrappingSlashes = /\/(.+)\//;

export function categoryPageUrl(category: $TSFixMe, basePath = "") {
  if (basePath && basePath !== null && basePath !== "") {
    // if basePath has surrounding slashes, strip them
    if (regexStripWrappingSlashes.test(basePath)) {
      basePath = basePath.match(regexStripWrappingSlashes)?.[1] || "";
    } else if (basePath === "/") {
      // avoid /%2F/${category}
      basePath = "";
    }
  }
  return pathToUrl(categoryPagePath(category, basePath));
}

export function organizationAdminCreatePath() {
  return `/admin/organizations/create`;
}

export function organizationAdminEditPath(organizationUid: $TSFixMe) {
  return `/admin/organizations/${organizationUid}/edit`;
}

export function groupsLandingPath() {
  return `/groups`;
}

export function groupsLandingUrl() {
  return pathToUrl(groupsLandingPath());
}

export function schoolAdminHomepage() {
  return "/schools";
}

export function schoolAnalyticsPath() {
  return "/schools/analytics";
}

export function employerAdminHomepage() {
  return "/employers";
}

export function employerAnalyticsPath() {
  return "/employers/analytics";
}

export function partnerAdminHomepage() {
  return "/partners";
}

export function partnerAnalyticsPath() {
  return "/partners/analytics";
}

export function classWalletOrderManagementUrl() {
  return Env.isProduction
    ? "https://classwallet.com/r/#/orders/manage"
    : "https://main-fe.stg.classwallet.dev/r/#/orders/manage";
}

export const cashCreditsPolicyPage = "/pages/cash-credits-card-policy";
