import { getReferrerAttribution } from "@outschool/attribution";
import { useLookupIP } from "@outschool/iplookup-client";
import { captureError } from "@outschool/platform";
import { signUpPath } from "@outschool/routes";
import { guessBrowserTimeZone } from "@outschool/time";
import { useAnalytics, useTrackEvent } from "@outschool/ui-analytics";
import {
  ContinueWithGoogleOneTap,
  useLoginWithGoogleOneTapMutationV2,
  useSession
} from "@outschool/ui-auth";
import { useNavigation } from "@outschool/ui-utils";
import React from "react";
import { useLocation } from "react-router";

import { GOOGLE_CLIENT_ID, isProduction } from "../../shared/Env";
import ActionType from "../actions/ActionType";
import { useAppState } from "../stores/AppStateProvider";

function useLoginWithGoogleOneTap({
  onSuccess,
  onError
}: {
  onSuccess: (sessionToken: string, isNewUser: boolean) => void;
  onError: (error?: Error) => void;
}) {
  const loginWithGoogle = useLoginWithGoogleOneTapMutationV2();

  const analytics = useAnalytics();
  const { ipInfoLoaded, isInGDPR } = useLookupIP();

  return React.useCallback(
    async (token: string) => {
      const createOptions = {
        attribution: await analytics.attribution(),
        browserTimeZone: guessBrowserTimeZone(),
        isGiftCardSignup: false,
        isLeader: false,
        subscribe: ipInfoLoaded ? !isInGDPR : false
      };

      const anonymousId = await analytics.anonymousId();
      const osRef = {
        ...getReferrerAttribution(),
        anonymousId
      };

      try {
        const { data } = await loginWithGoogle({
          variables: {
            createOptions,
            osRef,
            idToken: token
          }
        });

        if (
          data === undefined ||
          data?.loginOrCreateAccountWithGoogleV2 === undefined
        ) {
          onError(new Error("empty response"));
          return;
        }

        if (
          data?.loginOrCreateAccountWithGoogleV2.__typename === "LoginError"
        ) {
          onError(new Error("login error"));
        } else {
          const {
            loginOrCreateAccountWithGoogleV2: {
              isNewUser,
              authentication: { sessionToken }
            }
          } = data;
          onSuccess(sessionToken, isNewUser);
        }
      } catch (e) {
        onError(e);
      }
    },
    [analytics, loginWithGoogle, onSuccess, onError, ipInfoLoaded, isInGDPR]
  );
}

const SignupWithGoogleOneTap = ({}) => {
  const navigate = useNavigation();
  const appState = useAppState();
  const trackEvent = useTrackEvent();
  const location = useLocation();

  const { isLoggedIn } = useSession();

  const loginWithGoogle = useLoginWithGoogleOneTap({
    onSuccess: (sessionToken, isNewUser) => {
      isNewUser
        ? trackEvent("signup-by-google-onetap")
        : trackEvent("signin-by-google-onetap");
      appState.appDispatcher.dispatch(ActionType.User.LOGGED_IN, {
        sessionToken
      });
    },
    onError: error => {
      captureError(error);
      navigate(signUpPath());
    }
  });

  const shouldHide = React.useCallback(
    () =>
      /login|signup/.test(location.pathname) || /signup/.test(location.search),
    [location]
  );

  if (isLoggedIn) {
    return null;
  }

  return (
    <ContinueWithGoogleOneTap
      clientId={GOOGLE_CLIENT_ID as string}
      loginWithGoogle={loginWithGoogle}
      shouldHide={shouldHide}
      onLoad={() => {}}
      onPrompt={notification => {
        if (!isProduction) {
          console.debug(
            // eslint-disable-next-line i18next/no-literal-string
            "onetap: notdisplayed: ",
            notification.getNotDisplayedReason()
          );
          // eslint-disable-next-line i18next/no-literal-string
          console.debug("onetap: skipped: ", notification.getSkippedReason());
          console.debug(
            // eslint-disable-next-line i18next/no-literal-string
            "onetap: dismissed: ",
            notification.getDismissedReason()
          );
        }
        trackEvent("one-tap-prompt", {
          isDisplayed: notification.isDisplayed(),
          notDisplayReason: notification.getNotDisplayedReason(),
          skippedReason: notification.getSkippedReason(),
          getDismissedReason: notification.getDismissedReason()
        });
      }}
    />
  );
};

export default SignupWithGoogleOneTap;
